<template>
  <div>
    <LineChartGenerator
      :chart-options="chartOptions"
      :chart-data="modifiedChartData"
      :width="300"
      :height="300"
    />
  </div>
</template>

<script>
import { Line as LineChartGenerator } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  // Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  // Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);

export default {
  name: "LineChart",
  components: {
    LineChartGenerator
  },
  props: ["chartOptions", "chartData"],
  computed: {
    modifiedChartData() {
      const updatedData = Object.assign({}, this.chartData);
      updatedData.datasets.forEach(dataset => {
        const randomColor = this.getRandomColor();
        dataset.borderColor = randomColor; // Set the random line color here
        dataset.borderWidth = 1;
        dataset.pointRadius = 3;
      });
      return updatedData;
    }
  },
  mounted() {
    // this.chartData.datasets.forEach(dataset => {
    //   dataset.data = dataset.data.filter(value => value !== 0);
    // });
  },
  data() {
    return {
      // chartData: {
      //   labels: [
      //     "January",
      //     "February",
      //     "March",
      //     "April",
      //     "May",
      //     "June",
      //     "July",
      //   ],
      //   datasets: [
      //     {
      //       label: "Data One",
      //       backgroundColor: "#f87979",
      //       data: [1, 2, 3, 4, 5, 6, 7],
      //     },
      //   ],
      // },
    };
  },
  methods: {
    getRandomColor() {
      const letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    }
  }
};
</script>
